// extracted by mini-css-extract-plugin
export var actionsCell = "_PZ_5uQy";
export var actionsCellItem = "wLt0r_6_";
export var activeSortCell = "qafWYg5R";
export var alignCenterCell = "XIY0wmib";
export var alignEndCell = "Jv0jN0me";
export var bodyCell = "pE8qdZnL";
export var cellTag = "r7Qdu7bo";
export var fullContentCell = "ZUy5Yrpc";
export var headerCell = "JAGh1ujD";
export var hoverableRow = "FVvMElcD";
export var inactiveSortCell = "oAFLwc77";
export var loadingTable = "cKSLvWSk";
export var noInlineSpaceCell = "pjMwTYdT";
export var nowrapCell = "NaRrgOsh";
export var rowIcon = "t0qCcO1d";
export var secondaryValueLine = "jFvwLFA8";
export var selectedRow = "QPhFwjBw";
export var sortCell = "OYgY9JkU";
export var sortCellButton = "w4qFWBFI";
export var sortCellIcon = "qe3Yz0y2";
export var table = "UL018YUd";
export var tableFooter = "iR8m1Wij";
export var tablePageSizeSelector = "lwHuemXm";
export var valueLine = "AYVJItmZ";