import useLocationHash from '@finst/core/src/scripts/components/router/hooks/use-location-hash';
import useLocationSearchParamsState from '@finst/core/src/scripts/components/router/hooks/use-location-search-params-state';
import parseSortValues from '@finst/core/src/scripts/services/api/parse-sort-values';
import serializeSortValues from '@finst/core/src/scripts/services/api/serialize-sort-values';
import { useCallback, useMemo, useRef } from 'preact/compat';
import useAppSettings from '../../../../hooks/use-app-settings';
import { assetPositionsSortableColumns, bundlePositionsSortableColumns, defaultAssetPositionsSortValues, defaultBundlePositionsSortValues } from '../sorting';
export default function useWalletParams() {
    const { hash } = useLocationHash();
    const { update: updateAppSettings, ...appSettings } = useAppSettings();
    const defaultModeRef = useRef(appSettings.portfolioMode || 'basic');
    const { selectedAssetPositionId, selectedBundlePositionId } = useMemo(()=>{
        const searchParams = new URLSearchParams(hash);
        return {
            selectedAssetPositionId: searchParams.get('assetPositionId'),
            selectedBundlePositionId: searchParams.get('bundlePositionId')
        };
    }, [
        hash
    ]);
    const [urlParams, setUrlParams] = useLocationSearchParamsState((searchParams)=>{
        const mode = searchParams.get('mode');
        const assetPositionsSortValues = parseSortValues(searchParams.get('assetPositionsSorting') || '', assetPositionsSortableColumns);
        const bundlePositionsSortValues = parseSortValues(searchParams.get('bundlePositionsSorting') || '', bundlePositionsSortableColumns);
        return {
            mode: mode === 'advanced' || mode === 'basic' ? mode : defaultModeRef.current,
            showClosedPositions: searchParams.get('showClosedPositions') === 'true',
            assetPositionsSortValues: assetPositionsSortValues.size > 0 ? assetPositionsSortValues : defaultAssetPositionsSortValues,
            bundlePositionsSortValues: bundlePositionsSortValues.size > 0 ? bundlePositionsSortValues : defaultBundlePositionsSortValues
        };
    }, (params)=>({
            mode: params.mode,
            showClosedPositions: params.showClosedPositions || undefined,
            assetPositionsSorting: serializeSortValues(params.assetPositionsSortValues),
            bundlePositionsSorting: serializeSortValues(params.bundlePositionsSortValues)
        }));
    const setPortfolioMode = useCallback((mode)=>{
        setUrlParams((params)=>({
                ...params,
                mode
            }));
        updateAppSettings({
            portfolioMode: mode
        });
    }, [
        setUrlParams,
        updateAppSettings
    ]);
    return {
        selectedAssetPositionId,
        selectedBundlePositionId,
        urlParams,
        setUrlParams,
        setPortfolioMode
    };
}
