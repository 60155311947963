import useAsync from '@finst/core/src/scripts/hooks/use-async';
import { useMemo, useRef } from 'preact/compat';
import getAssets from '../services/asset/get-assets';
export default function useProgressiveAssetsLoader(assetSymbols) {
    const valueRef = useRef({});
    const assetSymbolsKey = useMemo(()=>[
            ...assetSymbols
        ].sort().toString(), [
        assetSymbols
    ]);
    const { isLoading } = useAsync(async ()=>{
        if (!assetSymbols.length) {
            valueRef.current = {};
            return;
        }
        const assetSymbolsToLoad = [];
        valueRef.current = assetSymbols.reduce((newAssetsStore, assetSymbol)=>{
            const asset = valueRef.current[assetSymbol];
            if (asset) {
                newAssetsStore[assetSymbol] = asset;
            } else {
                assetSymbolsToLoad.push(assetSymbol);
            }
            return newAssetsStore;
        }, {});
        const assets = await getAssets(assetSymbolsToLoad);
        valueRef.current = {
            ...valueRef.current,
            ...Object.fromEntries(assets.map((asset)=>[
                    asset.symbol,
                    asset
                ]))
        };
    }, [
        assetSymbolsKey
    ]);
    return {
        isLoading,
        value: valueRef.current
    };
}
