import { useContext, useEffect, useState } from 'preact/compat';
import { PortfolioBrokerContext } from '../global-contexts';
export default function usePortfolioBundlePositions() {
    const portfolioBroker = useContext(PortfolioBrokerContext);
    const [state, setState] = useState(undefined);
    useEffect(()=>{
        var _portfolioBroker;
        return (_portfolioBroker = portfolioBroker) === null || _portfolioBroker === void 0 ? void 0 : _portfolioBroker.onBundlePositions(setState);
    }, [
        portfolioBroker
    ]);
    return state;
}
