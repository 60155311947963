import { AppError } from '@finst/core/src/scripts/models/app-error';
import getAllBundles from './get-all-bundles';
export default async function getBundles(symbols) {
    const allBundles = await getAllBundles();
    const allBundlesDictionary = new Map(allBundles.map((bundle)=>[
            bundle.symbol,
            bundle
        ]));
    const bundles = symbols.reduce((bundles, symbol)=>{
        const bundle = allBundlesDictionary.get(symbol);
        if (bundle) {
            bundles.push(bundle);
        }
        return bundles;
    }, []);
    if (bundles.length !== symbols.length) {
        throw new AppError({
            code: 'NotFound'
        });
    }
    return bundles;
}
