import { useMemo } from 'preact/compat';
import useProgressiveAssetsLoader from './use-progressive-assets-loader';
export default function useAssetPositionsInfo(positions) {
    const assetSymbols = useMemo(()=>positions ? positions.map((position)=>position.assetSymbol) : [], [
        positions
    ]);
    const { isLoading, value: assetsDictionary } = useProgressiveAssetsLoader(assetSymbols);
    const value = useMemo(()=>{
        var _positions;
        return (_positions = positions) === null || _positions === void 0 ? void 0 : _positions.map((position)=>{
            const asset = assetsDictionary[position.assetSymbol];
            return asset && {
                position,
                asset
            };
        }).filter((positionInfo)=>positionInfo !== undefined);
    }, [
        positions,
        assetsDictionary
    ]);
    return {
        isLoading,
        value,
        error: undefined
    };
}
