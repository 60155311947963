import createSortValuesComparator from '@finst/core/src/scripts/services/api/create-sort-values-comparator';
import { useMemo } from 'preact/compat';
export default function useSortedBundlePositions(positions, sortValues) {
    const sortValuesKey = useMemo(()=>JSON.stringify([
            ...sortValues.entries()
        ]), [
        sortValues
    ]);
    return useMemo(()=>{
        const compare = createSortValuesComparator(sortValues, (item, sortColumn)=>{
            if (sortColumn === 'numberOfEntries') {
                return item.bundle.entries.length;
            }
            if (sortColumn === 'name') {
                return item.bundle[sortColumn];
            }
            return item.position[sortColumn];
        });
        return positions.slice(0).sort(compare);
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, [
        positions,
        sortValuesKey
    ]);
}
