import { useMemo } from 'preact/compat';
import isPositionOpen from '../../../../services/portfolio/is-position-open';
export default function useFilteredPositions(positions, params) {
    const { showClosed } = params;
    return useMemo(()=>{
        const openPositions = positions.filter(({ position })=>isPositionOpen(position) && (position.value === undefined || position.value >= 0.02));
        return {
            value: showClosed ? positions : openPositions,
            closedCount: positions && openPositions ? positions.length - openPositions.length : 0
        };
    }, [
        positions,
        showClosed
    ]);
}
