export const assetPositionsSortableColumns = [
    'name',
    'price',
    'value',
    'absoluteDailyPl',
    'absoluteUnrealisedPl',
    'absolutePositionPl',
    'absoluteTotalPl'
];
export const bundlePositionsSortableColumns = [
    'name',
    'numberOfEntries',
    'value',
    'absoluteDailyPl',
    'absoluteUnrealisedPl',
    'absolutePositionPl',
    'absoluteTotalPl'
];
export const defaultAssetPositionsSortValues = new Map([
    [
        'value',
        'DESC'
    ]
]);
export const defaultBundlePositionsSortValues = new Map([
    [
        'value',
        'DESC'
    ]
]);
