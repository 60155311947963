import { useMemo } from 'preact/compat';
import useProgressiveBundlesLoader from './use-progressive-bundles-loader';
export default function useBundlePositionsInfo(positions) {
    const bundleSymbols = useMemo(()=>positions ? positions.map((position)=>position.bundleSymbol) : [], [
        positions
    ]);
    const { isLoading, value: bundlesDictionary } = useProgressiveBundlesLoader(bundleSymbols);
    const value = useMemo(()=>{
        var _positions;
        return (_positions = positions) === null || _positions === void 0 ? void 0 : _positions.map((position)=>{
            const bundle = bundlesDictionary[position.bundleSymbol];
            return bundle && {
                position,
                bundle
            };
        }).filter((positionInfo)=>positionInfo !== undefined);
    }, [
        positions,
        bundlesDictionary
    ]);
    return {
        isLoading,
        value,
        error: undefined
    };
}
