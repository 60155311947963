import createSortValuesComparator from '@finst/core/src/scripts/services/api/create-sort-values-comparator';
import { useMemo } from 'preact/compat';
export default function useSortedAssetPositions(positions, sortValues) {
    const sortValuesKey = useMemo(()=>JSON.stringify([
            ...sortValues.entries()
        ]), [
        sortValues
    ]);
    return useMemo(()=>{
        const compare = createSortValuesComparator(sortValues, (item, sortColumn)=>{
            return sortColumn === 'name' ? item.asset[sortColumn] : item.position[sortColumn];
        });
        return positions.slice(0).sort(compare);
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, [
        positions,
        sortValuesKey
    ]);
}
