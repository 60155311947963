import useAsync from '@finst/core/src/scripts/hooks/use-async';
import { useMemo, useRef } from 'preact/compat';
import getBundles from '../services/bundle/get-bundles';
export default function useProgressiveBundlesLoader(bundleSymbols) {
    const valueRef = useRef({});
    const bundleSymbolsKey = useMemo(()=>[
            ...bundleSymbols
        ].sort().toString(), [
        bundleSymbols
    ]);
    const { isLoading } = useAsync(async ()=>{
        if (!bundleSymbols.length) {
            valueRef.current = {};
            return;
        }
        const bundleSymbolsToLoad = [];
        valueRef.current = bundleSymbols.reduce((newBundlesStore, bundleSymbol)=>{
            const bundle = valueRef.current[bundleSymbol];
            if (bundle) {
                newBundlesStore[bundleSymbol] = bundle;
            } else {
                bundleSymbolsToLoad.push(bundleSymbol);
            }
            return newBundlesStore;
        }, {});
        const bundles = await getBundles(bundleSymbolsToLoad);
        valueRef.current = {
            ...valueRef.current,
            ...Object.fromEntries(bundles.map((bundle)=>[
                    bundle.symbol,
                    bundle
                ]))
        };
    }, [
        bundleSymbolsKey
    ]);
    return {
        isLoading,
        value: valueRef.current
    };
}
